/*_______________fonts_______________*/
@font-face {
  font-family: 'Roboto-Thin'; /* Гарнитура шрифта */
  src: url('./assets/fonts/Roboto/Roboto-Thin.ttf'); /* Путь к файлу со шрифтом */
 }
 @font-face {
  font-family: 'Roboto-Light'; /* Гарнитура шрифта */
  src: url('./assets/fonts/Roboto/Roboto-Light.ttf');
    font-weight: bold;
    font-style: normal;
 }
 @font-face {
  font-family: 'Roboto-Regular'; /* Гарнитура шрифта */
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf');
    font-weight: 400;
    font-style: normal;
 }
 @font-face {
  font-family: 'Roboto-Medium'; /* Гарнитура шрифта */
  src: url('./assets/fonts/Roboto/Roboto-Medium.ttf');
    font-weight: 600;
    font-style: normal;
 }
 @font-face {
  font-family: 'Roboto-Bold'; /* Гарнитура шрифта */
  src: url('./assets/fonts/Roboto/Roboto-Bold.ttf');
    font-weight: 700;
    font-style: normal;
 }
 @font-face {
  font-family: 'Roboto-Black'; /* Гарнитура шрифта */
  src: url('./assets/fonts/Roboto/Roboto-Black.ttf');
    font-weight: bold;
    font-style: bold;
 }
 @font-face {
  font-family: 'ClearSans-Thin'; /* Гарнитура шрифта */
  src: url('./assets/fonts/Clear Sans/ClearSans-Thin.ttf');
 }
 @font-face {
  font-family: 'ClearSans-Regular'; /* Гарнитура шрифта */
  src: url('./assets/fonts/Clear Sans/ClearSans-Regular.ttf');
 }
 @font-face {
  font-family: 'ClearSans-Medium'; /* Гарнитура шрифта */
  src: url('./assets/fonts/Clear Sans/ClearSans-Medium.ttf');
 }
 @font-face {
  font-family: 'ClearSans-Light'; /* Гарнитура шрифта */
  src: url('./assets/fonts/Clear Sans/ClearSans-Light.ttf');
 }
 @font-face {
  font-family: 'ClearSans-Bold'; /* Гарнитура шрифта */
  src: url('./assets/fonts/Clear Sans/ClearSans-Bold.ttf');
 }
