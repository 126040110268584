.footer {
    
    width: 100%;
    background-color:#F1F3FE;
    padding: 40px 0;

    &_inner {
        max-width: 1184px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        box-sizing: border-box;

    }

    &_container {
        display: flex;
        justify-content: flex-start;
        gap: 100px;

        @media (max-width: 930px) {
            flex-direction:  column;
            gap: 20px;
        }
        

        &_logo {
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (max-width: 930px) {
                align-items: flex-start;
            }
        }

        &_contacts {
            font-family: 'ClearSans-Regular';
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            color: #111;

            &_title {
                font-weight: 400;
            }
                
            &_info {
                display: flex;
                justify-content: space-between;
                gap: 100px;

                @media (max-width: 930px) {
                    flex-direction:  column;
                    gap: 20px;
                }
                
            }
        }
    }

    &_info {
        &_name > span, &_email > span, &_mobile > span  {
            font-weight: 700;
        }
    }

    &_links {
        align-self: flex-end;
        margin-top: 30px;
    }

    &_scrooling_btn {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        border: 1px solid #38E7A0;
        position: relative;
        cursor: pointer;
        margin-left: 120px;
        position: fixed;
        bottom: 44px;
        right: 30px;
        z-index: 100;
        background: transparent;
        display: none;
        opacity: 0; // Изначально скрываем компонент 
        transition: opacity 0.4s;

        &--active {
            display: block;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 10px;
            left: 13px;
            width: 2px;
            height: 20px;
            transform: rotate(40deg);
            background-color: #38E7A0;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 10px;
            right: 13px;
            width: 2px;
            height: 20px;
            transform: rotate(-40deg);
            background-color: #38E7A0;
        }

        @media (max-width: 930px) {
            margin-left: auto;
        }
    }

    &_demo_btn {
        background-color: #38E7A0;
        max-width: 284px;
        width: 100%;
        height: 48px;
        border: none;
        border-radius: 24px;
        padding: 8px 32px;
        cursor: pointer;
        font-family: 'ClearSans-Regular';
        font-size: 16px;
        line-height: 22px;
        text-transform: uppercase;
        color: #111;
        margin-top: 34px;

        @media (max-width: 930px) {
            display: none;
        }
    }

    &_links {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        align-self: flex-end;

        // &_item {
        //     @media (max-width: 930px) {
        //         align-self: flex-start;
        //     }
        // }

        @media (max-width: 1360px) {
            padding-right: 70px;
        }
        @media (max-width: 930px) {
            align-self: auto;
            justify-content: flex-start;
        }
    }
}
