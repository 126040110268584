@import "_mixins.scss";
@import "_variables.scss";

@import "./fonts.scss";

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  @include media($mediapoint-very-small, $mediapoint-medium) {
    min-width: 300px;
  }

  a {
    text-decoration: none;
  }

  li {
    list-style: none;
  }
}

.container-page {
  margin: 0;
  padding: 0;
  // overflow-x: hidden;
  width: 100%;
  // max-width: 1200px;
  position: relative;

  // @include media($mediapoint-medium, $mediapoint-large) {
  //   margin: 0 25px 0 25px !important;
  // }

  // @include media($mediapoint-very-small, $mediapoint-medium) {
  //   min-width: 280px;
  // }
}

.btn {
  border-radius: 30px;
  border: 1px solid #38e7a0;
  background-color: transparent;
  cursor: pointer;
}

.underheader {

  &_container{
    display: flex;
    justify-content: center;
    // padding-top: 50px;
    padding: 0 20px;
    @media (max-width: 800px) {
      flex-direction: column;
    }

    @media (max-width: 1000px) {
      flex-direction: column;
      // padding-top: 0;
    }

    @media (max-width: 700px) {
      padding: 0 18px;
    }

    &_bg {
      background: linear-gradient(
        to bottom,
        #0e0e4f,
        #0b1d55,
        #053072,
        #0f4074,
        #0f6ab9
      );
    }

    &_first {
      display: flex;
      @media (max-width: 530px) {
        margin-left: -20px;
        max-width: 400px;
        padding-top: 22px !important;
      }
    }

    .underheader_logo {
      max-width: 484px;
      height: auto;
      position: relative;
      padding-right: 10px;
      
      @media (max-width: 530px) {
        width: 100%;
      }
    }
    
    &_second {
      padding: 60px 0 66px;
      max-width: 584px;
      width: 100%;

      @media (max-width: 530px) {
        padding: 30px 0;
        
      }

      &_subtitle {
        font-size: 16px;
        color: $blue-1;
        text-transform: uppercase;
        font-family: "ClearSans-Regular";
        font-weight: 400;
        margin: 0 0 24px;

        @media (max-width: 700px) {
          font-size: 14px;
          margin: 0 0 20px;
        }
      }

      &_title {
        font-family: "Roboto-Medium";
        margin: 0 0 20px;
        font-size: 44px;
        font-weight: 600;
        line-height: 52px;
        text-transform: uppercase;
        color: #fff;

        @media (max-width: 530px) {
          font-size: 28px;
          
        }
      }

      &_text {
        font-family: "ClearSans-Regular";
        font-size: 20px;
        font-weight: 400;
        color: #fcfcfc;
        margin: 0;

        &:last-child {
          margin-top: 20px;

          @media (max-width: 700px) {
           margin-top: 0px;
          }
        }
      }
    }
  }
}


.prime-title {
  color: #000;
  text-align: center;
  font-family: 'Roboto-Medium';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-transform: uppercase;

  
}

.prime-text {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 24px;
  font-style: normal;
  line-height: 32px;

  @media (max-width: 475px) {
    font-size: 20px;
}
}

.second-text {
  font-family: 'ClearSans-Regular';
  font-size: 20px;
  font-weight: 400;
  color: #111;
  font-style: normal;
  line-height: 28px;
}

.hover-dark-blue {
  transition: all .7s ease-in;
  position: relative;
  z-index: 1;
  background-image: linear-gradient(180deg, #F1F3FE 0%, #EDF0FF 100%) !important;
  

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // border-radius: 64px;
    border-radius: 40px;
    background-image: linear-gradient(180deg, #0E0E4F 0%, #0B1D55 26.12%, #053072 55.74%, #0F4074 79.37%, #0F6AB9 100%) !important;
    z-index: -1;
    transition: opacity .7s linear;
    opacity: 0;

    @media (max-width: 475px) {
      border-radius: 20px;
    }
  }

  

  &:hover::before {
    opacity: 1 !important;
  }
  
  &:hover > p {
    color: #FCFCFC !important;
  }
}

.hover-light-blue {
  transition: color 2s ease-in;
  position: relative;
  z-index: 1;
  // background-image: linear-gradient(180deg, #F1F3FE 0%, #EDF0FF 100%) !important;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 40px;
    background: var(--purple-linear, linear-gradient(180deg, #4877E7 0.5%, #5380EB 99.76%));
    box-shadow: 0px 4px 4px 0px #D9DFFF;
    z-index: -1;
    transition: opacity .7s linear;
    opacity: 0;

    @media (max-width: 475px) {
      border-radius: 20px;
    }
  }

  &:hover::before {
    opacity: 1 !important;
  }


  // &:hover {
  //   border-radius: 64px;
  //   border: 1px solid #E6E6E6;
  //   background: var(--purple-linear, linear-gradient(180deg, #4776E6 0%, rgba(142, 84, 233, 0.00) 100%)) !important;
  //   box-shadow: 0px 4px 4px 0px #D9DFFF;
  // }
  
  &:hover > p {
    color: #FCFCFC !important;
  }
}

// .sticky делает заголовки блоков липкими

.sticky {
  @media (max-width: 475px) {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    background: #F1F3FE;
    width: 100%;
  }
}

.hover-dark-anouther {
  transition: all .7s ease-in;
  position: relative;
  z-index: 1;
  background-image: linear-gradient(180deg, #F1F3FE 0%, #EDF0FF 100%) !important;

  & > p {
    transition: all .7s ease-in;
  }

  & svg path{
    transition: all .7s ease-in;
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px;
    background-image: linear-gradient(180deg, #0E0E4F 0%, #0B1D55 26.12%, #053072 55.74%, #0F4074 79.37%, #0F6AB9 100%) !important;
    z-index: -1;
    transition: opacity .7s linear;
    opacity: 0;

    // @media (max-width: 475px) {
    //   border-radius: 20px;
    // }
  }

  &:hover::before {
    opacity: 1 !important;
  }
  
  &:hover > p {
    color: #FCFCFC !important;
  }

  &:hover svg path {
    stroke: #FCFCFC;
  }

}
