@import "src/mixins.scss";
@import "src/variables.scss";

header {
	width: 100%;
	top: 0;
	z-index: 10000;
	background-color: white;

  .gradient {
    background: linear-gradient(90deg, #041351 0%, #0F125C 10.03%, #191166 18.75%, #1D116A 28.02%, #20116D 36.46%, #231170 45.33%, #271074 55.73%, #291176 65.05%, #2B1178 72.92%, #301A7F 80.70%, #332083 86.17%, #382789 93.12%, #3B2D8E 98.44%) !important;
  }

  .header-content {
    background-color: $blue-3;
    position: relative;
    height: 120px;
    margin: 0;
    display: flex;
    align-items: center;
    padding: 0 9%;
    z-index: 1000;

    @media (max-width: 700px) {
      padding: 0 18px;
    }

    &-container {
      display: flex;
      width: 100%;
      justify-content: space-between;

      @media (max-width: 1210px) {
        a {
          display: flex;
          align-items: center;
        }
      }

      .simplebuy-logo {
        max-width: 230px;

        @media (max-width: 700px) {
          max-width: 170px;
        }
      }
  
      .menu-container {
        display: flex;
        width: 100%;
        padding: 0 0 0 134px;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 1210px) {
          justify-content: flex-end;
          padding: 0px;
        }

        .menu {
          @media (max-width: 1210px) {
            display: none;
          }
        }

        .header-link {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #fff;
          font-size: 14px;
          font-family: "Roboto-Regular";

          @media (max-width: 1210px) {
            display: none;
          }
        }

        .header-btn {
          color: #57f1b4;
          width: 140px;
          height: 48px;
          cursor: pointer;
          transition: all 0.15s;
        
          &:hover {
            color: #00FF98;
            border: 2px solid #00FF98;
            transition: all 0.15s;
          }

          @media (max-width: 600px) {
            width: 88px;
          }
        }
        
        .burger-menu_button {
          display: none;
          flex-direction: column;
          position: relative;
          gap: 10px;
          margin-left: 24px;
          cursor: pointer;

          @media (max-width: 1210px) {
            display: flex;
          }

          .burger-menu_line, &::before, &::after  {
            display: block;
            content: "";
            width: 28px;
            height: 1.1px;
            background-color: #EFEFEF;
            border-radius: 20px;
            position: relative;
            left: -9.8px;
            transition: all 0.25s ease-in-out;
          }
        }
        
        .menu_button_open {

          .burger-menu_line {
            opacity: 0;
            position: relative;
            left: 0;
          }
        
          &::before {
            transform: translate(0, 9px) rotate(-45deg);
          }
        
          &::after {
            transform: translate(0, -12.5px) rotate(45deg);
          }
        }
      }
    }
  }
}

.drop-down-menu-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -110px;
  padding-top: 32px;
  width: 100%;
  opacity: 0;
  // background: linear-gradient(90deg, #041351 0%, #0F125C 10.03%, #191166 18.75%, #1D116A 28.02%, #20116D 36.46%, #231170 45.33%, #271074 55.73%, #291176 65.05%, #2B1178 72.92%, #301A7F 80.70%, #332083 86.17%, #382789 93.12%, #3B2D8E 98.44%) !important;
  // height: 260px;
  margin: 0;
  align-items: center;
  background: transparent;
  z-index: 100;
  // transform: translateX(-50px);
  transition: all 0.5s;

  p {
    color: #EFEFEF;
    font-family: 'ClearSans-Thin';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    text-transform: uppercase;
    opacity: 0;
    transition: all 0.5s;
  }

  span {
    opacity: 0;
    transition: all 0.5s;
  }

  @media (min-width: 1210px) {
    display: none;
  }

  .menu-title-arrow {
    display: flex;
  }

  .menu-title-arrow:before, .menu-title-arrow:after{
    position: relative;
  }

  .menu-title-arrow:before {
    left: 1.1px;
  }

  .mobile-menu {

    .mobile-menu-title {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }

    .mobile-menu-dropdown {
      margin: 0;
      opacity: 0;
      height: 0px;
      background: #fff;
      border-radius: 20px;
      z-index: 1;
      // transition:        0.2s opacity;
    
      a {
        display: flex;
        flex-direction: column-reverse;
        text-transform: uppercase;
        font-family: "Roboto-Regular";
        font-size: 13px;
        color: black;
        font-weight: bold;
        text-decoration: none;
      }
      .menu-link {
        width: 0;
        height: 0;
      }
    
      .menu-link:hover:before {
        -webkit-transform: scaleX(1);
        -ms-transform: scaleX(1);
        transform: scaleX(1);
        z-index: 10000;
        -webkit-transform-origin: left;
        -ms-transform-origin: left;
        transform-origin: left;
        -webkit-transition: -webkit-transform 0.25s;
        transition: -webkit-transform 0.25s;
        -o-transition: transform 0.25s;
        transition: transform 0.25s;
        transition: transform 0.25s, -webkit-transform 0.25s;
      }
      
      .menu-link:before {
        content: '';
        z-index: 10000;
        height: 1px;
        background-color: #38E7A0;
        position: relative;
        left: 0; bottom: -3px;
        -webkit-transform: scaleX(0);
        -ms-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: right;
        -ms-transform-origin: right;
        transform-origin: right;
        -webkit-transition: -webkit-transform 0.25s;
        transition: -webkit-transform 0.25s;
        -o-transition: transform 0.25s;
        transition: transform 0.25s;
        transition: transform 0.25s, -webkit-transform 0.25s;
      }
    }
  }

  .mobile-menu:hover {

    .menu-title-arrow::after {
      transform: translate(3.7px, 0) rotate(-35deg);
      transition: all 0.25s ease-in-out;
    }
    .menu-title-arrow::before {
      transform: translate(-3.3px, 0) rotate(35deg);
      transition: all 0.25s ease-in-out;
    }
    
    .mobile-menu-dropdown {
      opacity: 1;
      height: auto;
      padding: 20px 32px;
      text-align: center;
      transition:
        // 0.5s padding,
        0.2s opacity,
        0.5s background;
    

      .menu-link {
        width: 100%;
        height: auto;
        padding: 10px 0;
      }
    }
  }
}

.drop-down-menu-list-open{
  opacity: 10;
  // width: 100%;
  background-color: #0E0E4F;
  transform: translateY(210px);

  p {
    opacity: 10;
    transition: all .8s;
  }

  span {
    opacity: 10;
    transition: all .8s;
  }

  .mobile-menu {
    .mobile-menu-title {

    }
  }
}
