﻿$blue-1: #06A8E0;
$blue-2: #00316F;
$blue-3: #0E0E4F;
$grey-0: #EDF2F3;
$grey-1: #C7D6D9;
$grey-2: #92ADB3;
$grey-3: #5E7073;
$grey-4: #3F4B4D;
$text-active: $grey-3;

$light-green: #E3F7DA;
$green: #38E7A0;
$green2: #7CA32A;
$green-hover: #7BD057;
$green-action: #3F8024;
$dark-green: #1E3D11;
$dark-green-action: #13260B;


$orange-hover: #F9BE5A;
$orange: #F7A823;
$orange-action: #C88007;

$grey: #CCCCCC;
$grey-hover: #A7A7A7;
$grey-action: #4F4F4F;

$blue: #00B9D4;
$blue-hover: #0094AA;
$blue-action: #006D7D;



//классы шаблоны
%d-flex-row {
  display: flex;
  flex-direction: row;
}

%d-flex-col {
  display: flex;
  flex-direction: column;
}

%d-flex-center-center {
  align-items: center;
  justify-content: center;
}

%d-flex-between-center {
  align-items: center;
  justify-content: space-between;
}

%d-flex-start-center {
  align-items: center;
  justify-content: flex-start;
}

%d-flex-end-center {
  align-items: center;
  justify-content: flex-end
}

$mediapoint-very-small: 0;
$mediapoint-small-360: 360;
$mediapoint-small: 575;
$mediapoint-medium: 768;
$mediapoint-medium-medium: 833;
$mediapoint-medium-large: 992;
$mediapoint-large: 1275;
