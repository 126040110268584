
  .gradient {
    background: linear-gradient(90deg, #041351 0%, #0F125C 10.03%, #191166 18.75%, #1D116A 28.02%, #20116D 36.46%, #231170 45.33%, #271074 55.73%, #291176 65.05%, #2B1178 72.92%, #301A7F 80.70%, #332083 86.17%, #382789 93.12%, #3B2D8E 98.44%) !important;
  }

.preloader {
  // position: absolute;
  display: flex;
  align-items: flex-start;
  padding-top: 200px;
  justify-content: center;
  background-color: #0E0E4F;
  width: 100%;
  height: 700px;
  background-size: cover;
  z-index: 100;
  background-repeat: no-repeat;
  backdrop-filter: blur(5px);
}
// #4776E6 100%+ #8E54E9 0%

.loader,
.loader:after {
  overflow:hidden;
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(101,171,248, 0.2);
  border-right: 1.1em solid rgba(101,171,248, 0.2);
  border-bottom: 1.1em solid rgba(101,171,248, 0.2);
  border-left: 1.1em solid #4776E6;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
