@import "src/mixins.scss";
@import "src/variables.scss";

.menu {
  display: flex;
  cursor: pointer;
}

.menu-title {
  display: flex;
  align-items: center;
  width: 100%;
  // height: 40px;
  text-align: center;
  color: #ffffff;
  transition: 0.3s background-color;
  gap: 15px;

  p {
    font-family: "Roboto-Regular";
    text-transform: uppercase;
    font-size: 14px;
  }
  
  &-arrow:before, &-arrow:after {
    content: "";
    position: absolute;
    background-color: #38E7A0;
    width: 1.1px;
    height: 13px;
  }

  .top-position:before, .top-position:after {
    top: 53px;
  }

  &-arrow:before {
    transform: translate(-3.7px, 0) rotate(-35deg);
    transition: all 0.25s ease-in-out;
  }
  &-arrow:after {
    transform: translate(3.3px, 0) rotate(35deg);
    transition: all 0.25s ease-in-out;
  }
}

.menu:hover > .menu-title:before {
  border-top-width: 0;
  transition:
    0.2s border-top-width ease-in,
    0.3s border-top-color;
}

.menu:hover > .menu-title:after {
  border-bottom-width: 5px;
  transition: 0.2s 0.2s border-bottom-width ease-out;
}

.menu-dropdown {
  padding: 20px 24px;
  opacity: 0;
  position: absolute;
  top: 70px;
  background: #fff;
  border-radius: 20px;
  z-index: 10000;
  transition:
    0.5s padding,
    0.2s opacity,
    0.5s background;

  a {
    display: flex;
    flex-direction: column-reverse;
    text-transform: uppercase;
    font-family: "Roboto-Regular";
    font-size: 13px;
    color: black;
    text-decoration: none;

    &:hover {
      font-weight: bold;
    }
  }

  .menu-link:hover:before {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
    z-index: 10000;
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
    -webkit-transition: -webkit-transform 0.25s;
    transition: -webkit-transform 0.25s;
    -o-transition: transform 0.25s;
    transition: transform 0.25s;
    transition: transform 0.25s, -webkit-transform 0.25s;
  }
  
  .menu-link:before {
    content: '';
    // width: 60px;
    z-index: 10000;
    height: 1px;
    background-color: #38E7A0;
    position: relative;
    left: 0; bottom: -3px;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: right;
    -ms-transform-origin: right;
    transform-origin: right;
    -webkit-transition: -webkit-transform 0.25s;
    transition: -webkit-transform 0.25s;
    -o-transition: transform 0.25s;
    transition: transform 0.25s;
    transition: transform 0.25s, -webkit-transform 0.25s;
  }
}

.menu:hover {
  z-index: 10000;
  .menu-dropdown {
    opacity: 1;
    transition: 
    0.5s height cubic-bezier(.73,.32,.34,1.5),
    // 0.2s padding cubic-bezier(.73,.32,.34,1.5), // трансформация ширины выпадающего меню
    0.5s margin cubic-bezier(.73,.32,.34,1.5),
    0.5s 0.2s color,
    0.2s opacity,
    0.2s background-color;
    z-index: 10000;
  }

  .menu-title-arrow::after {
    transform: translate(3.7px, 0) rotate(-35deg);
    transition: all 0.25s ease-in-out;
  }
  .menu-title-arrow::before {
    transform: translate(-3.3px, 0) rotate(35deg);
    transition: all 0.25s ease-in-out;
  }
}

.menu:not(:hover) > .menu-dropdown {
  padding: 0px;
  background: #fff;
  z-index: 99;
  z-index: 10000;
}

.menu:not(:hover) > .menu-dropdown:after {
  border-top-color: #fff;
}

.menu:not(:hover) > .menu-title:after {
  border-bottom-color: #fff;
}

.menu-dropdown > * {
  overflow: hidden;
  height: 30px;
  padding: 5px 10px;
  background: rgba(0,0,0,0);
  white-space: nowrap;
  opacity: 1;
  z-index: 10000;
  transition: 
    0.5s height cubic-bezier(.73,.32,.34,1.5),
    0.5s padding cubic-bezier(.73,.32,.34,1.5),
    0.5s margin cubic-bezier(.73,.32,.34,1.5),
    0.5s 0.2s color,
    0.2s background-color;
}

.menu-dropdown > *:hover {
  opacity: 1;
  z-index: 10000;
}

.menu:not(:hover) > .menu-dropdown > * {
  visibility: hidden;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  color: rgba(25,25,25,0);
  opacity: 1;
  z-index: 10000;
  transition: 
    0.5s 0.1s height,
    0.5s 0.1s padding,
    0.5s 0.1s margin,
    0.3s color,
    0.6s visibility;
  z-index: 99;
}
